#impact-pledge {
  .box {
    .card > div > div {
      &:first-child {
        @apply absolute bottom-0 z-[1] custom-shadow-soft;
      }

      &:last-child {
        @apply z-[2];
      }
    }

    &:nth-child(even) {
      .card {
        @apply bg-green-linear-gradient;
      }
    }

    &:nth-child(odd) {
      .card {
        h3 {
          @apply text-green-400;
        }
      }
    }
  }
}
