#post {
  #post-content {
    @apply mt-8 md:mt-12;

    h2 {
      @apply block text-left;

      &::after,
      &::before {
        @apply content-none border-none;
      }
    }

    a {
      @apply text-green-400 hover:text-green-600;
    }

    ol {
      @apply text-base leading-6 md:text-lg md:leading-7 list-decimal;
    }

    ul {
      @apply text-base leading-6 md:text-lg md:leading-7 list-disc;
    }

    ol,
    ul {
      @apply pl-5 text-white;
      margin-bottom: 1rem !important;

      li {
        @apply mb-2 pl-2;

        &::marker {
          @apply text-green;
        }
      }
    }

    p {
      margin-bottom: 1rem !important;
    }

    svg > path {
      fill: #fff !important;
    }
  }
}
