#digital-knowledge-dissemination {
  li {
    @media screen and (max-width: 767px) {
      @apply text-base leading-6;
    }

    @media screen and (min-width: 768px) {
      @apply text-lg leading-7;
    }
  }

  #our-applications {
    a {
      & > img {
        @apply transition-all duration-300;

        &:hover {
          filter: brightness(0.75);
        }
      }
    }
  }
}
