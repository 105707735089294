@use './../../styles/base/colors' as *;

.custom-slick {
  .slick-track {
    @apply flex;
  }

  .slick-slide {
    @apply flex mx-4 p-8 h-auto bg-white cursor-grab opacity-50 custom-shadow-soft;

    &.slick-current {
      @apply opacity-100;
    }

    > div {
      @apply w-full;
    }
  }

  .custom-slick-slide {
    @apply flex-col justify-between h-full;
    display: flex !important;
  }

  .slick-dots {
    @apply relative mt-8;

    li {
      button {
        @apply w-4 h-4 bg-transparent rounded-full;
        border: 2px solid $white !important;

        &::before {
          content: '';
        }

        &:hover {
          background: $silver;
        }
      }

      &.slick-active {
        button {
          @apply bg-white;
        }
      }
    }
  }
}
