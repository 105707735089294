@use './../base/colors' as *;

@layer utilities {
  .bg-mine-shaft {
    background-color: $mine-shaft;
  }

  .bg-tundora {
    background-color: $tundora;
  }

  .bg-green-linear-gradient {
    background: linear-gradient(
      90deg,
      #69bc8d 0%,
      #59b581 21.61%,
      #4dac76 59.38%,
      #2a9763 92.98%,
      #23935e 100%
    );
  }
}
