.slideshow {
  @apply relative w-full max-w-full rounded-3xl overflow-hidden z-0;

  .slides {
    @apply flex whitespace-nowrap transition-all duration-500;
  }

  .slide {
    @apply flex-[0_0_100%] w-full object-cover;
  }

  .dots {
    @apply absolute left-0 right-0 bottom-1 md:bottom-4 text-center;
  }

  .dot {
    @apply inline-block mx-2 w-12 h-1 bg-gray-400 cursor-pointer;
  }

  .dot.active {
    @apply bg-green-400;
  }
}
