#privacy-policy {
  #privacy-policy-content {
    h1 {
      @apply mb-8;
    }

    h2 {
      @apply mt-8 mb-4 block text-left;

      &::after,
      &::before {
        @apply content-none border-none;
      }
    }

    ol {
      @apply list-decimal;
    }

    ul {
      @apply list-disc;
    }

    ol,
    ul {
      @apply mb-4 pl-5 text-white;

      li {
        @apply mb-2 pl-2;

        &::marker {
          @apply text-green;
        }
      }
    }

    p {
      margin-bottom: 1rem !important;
    }
  }
}
