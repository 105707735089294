@use 'base';
@use 'components';
@use 'utilities';

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .custom-shadow-soft {
    box-shadow: 6px 6px 14px rgba(0, 0, 0, 0.2) !important;
  }

  .custom-shadow-hard {
    box-shadow: 0 0 50px #000 !important;
  }
}
