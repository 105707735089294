#references {
  .fade:first-child {
    #reference {
      .overlay-background {
        > img:first-child {
          @apply max-w-[200px] md:max-w-[300px];
        }
      }
    }
  }
}
