header {
  @apply shadow-2xl transition-all duration-500;

  .logo {
    @apply w-full;
  }

  .menu-bar,
  .logo {
    @apply transition-all duration-500;
  }

  .menu-icon {
    &.open span {
      &:nth-child(1) {
        @apply w-0 top-2.5 left-1/2;
      }

      &:nth-child(2) {
        @apply rotate-45;
      }

      &:nth-child(3) {
        @apply -rotate-45;
      }

      &:nth-child(4) {
        @apply w-0 top-[18px] left-1/2;
      }
    }

    span {
      @apply transition-all duration-300 rotate-0;

      &:nth-child(1) {
        @apply top-0;
      }

      &:nth-child(2),
      &:nth-child(3) {
        @apply top-2.5;
      }

      &:nth-child(4) {
        @apply top-5;
      }
    }
  }

  nav {
    a {
      &:not(.btn) {
        @apply border-b-2 border-solid border-transparent;

        &.active {
          @apply border-green-400;
        }

        &:focus,
        &:hover {
          @apply border-green-600;
        }
      }
    }
  }
}

@media screen and (max-width: 1023px) {
  header {
    .menu {
      @apply h-full top-20 bottom-0 right-0 w-0 transition-all duration-300;

      &:not(.show) {
        @apply block overflow-hidden;
      }

      &.show {
        @apply right-0 w-full overflow-hidden;
      }

      nav {
        @apply top-1/2 left-1/2 -translate-x-1/2 -translate-y-full;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  header {
    .logo {
      @apply max-w-[150px];
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  header {
    .logo {
      @apply max-w-[175px];
    }
  }
}

@media screen and (min-width: 1280px) {
  header {
    .logo {
      @apply max-w-[200px];
    }
  }
}
