// White
$white: #fff;

// Yellow
$scotch-mist: #fffad8;
$buttermilk: #fff5b2;
$sweet-corn: #fbee8d;
$gorse: #ffeb3b;

// Pink
$carousel-pink: #fbe7ef;

// Green
$silver-tree: #69bc8d;
$ocean-green: #4dac76;

// Blue
$tangaroa: #021037;
$lucky-point: #20206a;
$steel-blue: #4184b1;
$malibu: #62e7f7;

// Grey
$silver: #cdcdcd;
$tundora: #424242;

// Black
$cod-gray: #191919;
$mine-shaft: #212121;
