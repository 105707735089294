@use './../../styles/base/transitions' as *;

.fade {
  opacity: 0;
  visibility: hidden;
  transition: opacity $transition-duration-300ms $transition-out,
    transform $transition-duration-300ms $transition-out;
  will-change: opacity, visibility;

  &.fade-up {
    transform: translateY(20px);
  }

  &.fade-down {
    transform: translateY(-20px);
  }

  &.fade-right {
    transform: translateX(-20px);
  }

  &.fade-left {
    transform: translateX(20px);
  }

  &.is-visible {
    opacity: 1;
    transform: none;
    visibility: visible;
  }
}
