.carousel {
  @apply relative w-full h-[150px] overflow-hidden select-none;

  .slides {
    @apply flex whitespace-nowrap transition-all duration-500 cursor-pointer;
  }

  .slide {
    @apply flex-[0_0_50%] md:flex-[0_0_25%] flex flex-row justify-center items-center h-[150px];
  }
}
