@layer base {
  *,
  ::after,
  ::before {
    box-sizing: border-box;
  }

  body {
    @apply bg-mine-shaft;
  }

  h1 {
    @apply text-white text-2xl leading-[46px] md:text-4xl md:leading-[64px] lg:text-6xl lg:leading-[90px] font-bold;
  }

  h2 {
    @apply flex justify-center items-center mb-10 md:mb-14 w-full text-white text-xl leading-9 md:text-3xl md:leading-[54px] text-center font-medium uppercase whitespace-pre-line;

    &::after,
    &::before {
      @apply content-[''] flex-[1_0_20px] border-t-2 border-solid border-green-400;
    }

    &::after {
      @apply ml-4 md:ml-8;
    }

    &::before {
      @apply mr-4 md:mr-8;
    }
  }

  h3 {
    @apply mb-4 text-white text-xl leading-9 md:text-2xl md:leading-[46px] font-medium;
  }

  h4 {
    @apply mb-2 text-green-400 text-lg leading-7 md:text-xl md:leading-9 font-medium;
  }

  h5 {
    @apply text-white text-base leading-6 md:text-lg md:leading-7 font-medium text-left;
  }

  p {
    @apply text-white text-base leading-6 md:text-lg md:leading-7;
  }

  a {
    @apply inline-block text-white leading-normal;

    &:not(.btn) {
      &.active {
        @apply text-green-400;
      }

      &:focus,
      &:hover {
        @apply text-green-600;
      }
    }
  }

  a,
  button {
    &:focus,
    &:hover {
      @apply transition-all duration-200;
    }
  }

  small {
    @apply text-xs leading-[18px];
  }

  li {
    @apply mb-2;

    &::marker {
      @apply text-green;
    }
  }

  input:focus,
  select:focus,
  textarea:focus,
  button:focus {
    outline: none !important;
  }
}

.page-title-hidden {
  @apply absolute m-0 p-0 w-px h-px overflow-hidden;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
}

.centered {
  @apply absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2;
}

.bg-left-side {
  background: url('src/client/assets/images/background-left-side.svg') no-repeat;
}

.bg-right-side {
  background: url('src/client/assets/images/background-right-side.svg')
    no-repeat;
}

.bg-both-side {
  background:
    url('src/client/assets/images/background-right-side.svg') right top
      no-repeat,
    url('src/client/assets/images/background-left-side.svg') left bottom
      no-repeat;
}

.bg-right-center {
  background-position: right center;
}

.overlay-video {
  background: linear-gradient(
    90deg,
    #67bc8d 0%,
    #60b888 21.61%,
    #4bab7a 59.37%,
    #2a9763 92.98%,
    #23935e 100%
  );
  opacity: 0.6;
}
