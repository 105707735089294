#reference {
  .overlay {
    &-background {
      @apply absolute top-0 bottom-0 left-0 right-0 flex flex-row justify-center items-center w-full h-full overflow-hidden;
      background-color: rgba(0, 0, 0, 0.75);
      animation: show 0.2s ease-out forwards;
    }
  }

  &:hover {
    .overlay-background {
      animation: hide 0.2s ease-out forwards;
    }

    img:first-child {
      @apply scale-105;
    }
  }
}

@keyframes show {
  0% {
    @apply h-0;
  }
  50% {
    @apply h-1/2;
  }
  100% {
    @apply h-full;
  }
}

@keyframes hide {
  0% {
    @apply h-full;
  }
  50% {
    @apply h-1/2;
  }
  100% {
    @apply h-0;
  }
}
