.member {
  &-picture {
    @apply relative p-8 w-full h-full;

    img {
      @apply left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 w-full h-full;
      transition: visibility 0s linear 0s, opacity 300ms;

      &:first-child {
        @apply absolute visible opacity-100;
      }

      &:last-child {
        @apply relative w-full invisible opacity-0 rounded-full;
      }
    }

    &:focus,
    &:hover {
      img {
        &:first-child {
          @apply invisible opacity-0;
        }

        &:last-child {
          @apply visible opacity-100;
        }
      }
    }
  }

  &-info {
    @apply text-center;
  }
}
