#services {
  .tap {
    @apply absolute left-0 right-0 mx-auto mt-1 w-full max-w-[30px];
    animation: bounce 2s ease infinite;
  }

  @media screen and (min-width: 768px) {
    .world-map {
      background: url('src/client/assets/images/world-map.webp') no-repeat;
      background-position: center;
    }
  }

  @media screen and (max-width: 1023px) {
    .world-map {
      background-size: 95%;
    }
  }

  @media screen and (min-width: 1024px) {
    .world-map {
      background-size: contain;
    }
  }

  @keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-8px);
    }
    60% {
      transform: translateY(-4px);
    }
  }
}
