@use './../../styles/base/colors' as *;

footer {
  a {
    @apply text-base;
    color: $silver;
  }

  .links {
    & > .contacts {
      a {
        &:not(.btn) {
          &.active {
            @apply underline decoration-2 decoration-green-400 underline-offset-4;
          }

          &:focus,
          &:hover {
            @apply underline decoration-2 decoration-green-600 underline-offset-4;
          }
        }
      }
    }

    & > div:not(.contacts) {
      a {
        &:not(.btn) {
          @apply border-b-2 border-solid border-transparent;

          &.active {
            @apply border-green-400;
          }

          &:focus,
          &:hover {
            @apply border-green-600;
          }
        }
      }
    }
  }
}
