#landing-intro {
  .logo {
    @apply w-full transition-all duration-500;
  }

  .menu-icon {
    &.open span {
      &:nth-child(1) {
        @apply w-0 top-2.5 left-1/2 bg-black;
      }

      &:nth-child(2) {
        @apply rotate-45 bg-black;
      }

      &:nth-child(3) {
        @apply -rotate-45 bg-black;
      }

      &:nth-child(4) {
        @apply w-0 top-[18px] left-1/2 bg-black;
      }
    }

    span {
      @apply transition-all duration-300 rotate-0;

      &:nth-child(1) {
        @apply top-0;
      }

      &:nth-child(2),
      &:nth-child(3) {
        @apply top-2.5;
      }

      &:nth-child(4) {
        @apply top-5;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  #landing-intro {
    .logo {
      @apply max-w-[150px];
    }
  }
}

@media screen and (max-width: 1023px) {
  #landing-intro {
    .menu {
      @apply h-full top-0 bottom-0 right-0 w-0 z-[1000] transition-all duration-300;

      &:not(.show) {
        @apply block overflow-hidden;
      }

      &.show {
        @apply right-0 w-full overflow-hidden;
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  #landing-intro {
    .logo {
      @apply max-w-[175px];
    }
  }
}

@media screen and (min-width: 1280px) {
  #landing-intro {
    .logo {
      @apply max-w-[200px];
    }
  }
}
