#blog {
  #latest-blog-posts {
    .post-content {
      a {
        @apply text-green-400 hover:text-green-600;
      }

      ol,
      ul {
        @apply text-white;
      }
    }

    #searchText {
      &::placeholder {
        color: #fff;
      }

      &::-ms-input-placeholder {
        color: #fff;
      }
    }
  }
}
