$fontFamilyRegular: 'Red Hat Text Regular';
$fontFamilyMedium: 'Red Hat Text Medium';
$fontFamilyBold: 'Red Hat Text Bold';

@font-face {
  font-family: $fontFamilyRegular;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('src/client/assets/fonts/RedHatText-Regular.ttf') format('truetype');
}

@font-face {
  font-family: $fontFamilyMedium;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('src/client/assets/fonts/RedHatText-Medium.ttf') format('truetype');
}

@font-face {
  font-family: $fontFamilyBold;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('src/client/assets/fonts/RedHatText-Bold.ttf') format('truetype');
}
