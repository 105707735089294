#video-player {
  .modal {
    @apply fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 p-2 w-full lg:max-w-[1000px] z-[1002];
  }

  .modal-open {
    visibility: visible;
    animation: moveUp 0.7s ease-out forwards;
  }

  .modal-close {
    visibility: hidden;
    animation: moveDown 0.7s ease-out forwards;
  }

  @keyframes moveUp {
    0% {
      @apply opacity-0;
      transform: translate(-50%, 100%);
    }
    100% {
      @apply opacity-100;
      transform: translate(-50%, -50%);
    }
  }

  @keyframes moveDown {
    0% {
      @apply opacity-100;
      transform: translate(-50%, -50%);
    }
    100% {
      @apply opacity-0;
      transform: translate(-50%, 100%);
    }
  }
}
